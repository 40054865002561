import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Particle from '../components/particle'
import {Link} from 'gatsby'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <div className="z-10 items-center overflow-hidden flex-grow">
        <div className="container mx-auto px-6 flex pt-16 lg:pt-32 pb-8">
          <Particle />
          <div className="sm:w-2/3 lg:w-3/5 flex flex-col z-10">
            {/* <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span> */}
            <h1 className="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-800">
            Greetings,
                <span className="text-5xl sm:text-7xl">
                My name is Rijad Husić,
                </span>
            </h1>
            <p className="text-xl mt-4 text-gray-500 dark:text-white">
            a proficient Full-stack web developer situated in Bosnia and Herzegovina. My expertise lies in the development of custom solutions and E-commerce platforms, acquired through years of experience.
            </p>
            <p className="text-xl mt-4 text-gray-500 dark:text-white">
            Please explore my web portfolio page to review my latest projects and blog posts. Thank you for considering my services.
            </p>
            <div className="flex mt-8">
                <Link to="/contact" className="bg-blue-600 px-10 py-3 rounded text-white hover:bg-blue-500">
                    Contact Me
                </Link>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default IndexPage
