import React from 'react'
import Particles from 'react-particles-js';

function Particle (props) {
return (
  <Particles className="w-full h-full absolute top-0 left-0 z-0 bg-gray-100" params={{
    fpsLimit: 60,
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          area: 800
        }
      },
      color: {
        value: "#000000",
      },
      shape: {
        type: "circle",
        options: {
          polygon: {
            sides: 5
          },
        }
      },
      stroke: {
        width: 0
      },
      opacity: {
        value: 0.2,
        random: false,
        animation: {
          enable: false,
          speed: 3,
          minimumValue: 0.1,
          sync: false
        }
      },
      size: {
        value: 3,
        random: true,
        animation: {
          enable: false,
          speed: 20,
          minimumValue: 0.1,
          sync: false
        }
      },
      links: {
        enable: true,
        distance: 100,
        color: "#000000",
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        speed: 4,
        direction: "none",
        random: false,
        straight: false,
        outMode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detectsOn: "canvas",
      modes: {
        grab: {
          distance: 400,
          links: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 0.8
        },
        repulse: {
          distance: 200
        },
        push: {
          quantity: 4
        },
        remove: {
          quantity: 2
        }
      }
    },
    detectRetina: true,
  }}/>
  )
}

export default Particle

